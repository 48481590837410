<template>
  <div>
    <el-form
      :label-position="'left'"
      label-width="80px"
      @submit.prevent="onSubmit"
      style="margin: 20px; width: 60%; min-width: 600px"
    >
      <el-form-item label="id">
        <el-input v-model="marketRecite.id" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="标题">
        <el-input v-model="marketRecite.title"></el-input>
      </el-form-item>
      <el-form-item label="副标题">
        <el-input v-model="marketRecite.subTitle"></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input
          type="textarea"
          v-model="marketRecite.remark"
          :rows="8"
        ></el-input>
      </el-form-item>
      <el-form-item label="绑定教学">
        <el-input
          placeholder="教学ID"
          v-model="marketRecite.teachId"
        ></el-input>
        <el-button @click="dialogTeachVisible = true">绑定</el-button>
      </el-form-item>
      <el-form-item label="绑定活码">
        <el-input
          placeholder="活码ID"
          v-model="marketRecite.qrCodeId"
        ></el-input>
      </el-form-item>
    </el-form>

    <el-form
      :label-position="'left'"
      label-width="80px"
      style="margin: 20px; width: 60%; min-width: 600px"
    >
      <el-form-item class="bottomBtn">
        <el-button @click="onAdd">保存</el-button>
        <el-button>取消</el-button>
      </el-form-item>
    </el-form>

    <el-dialog
      title="提示"
      :visible.sync="dialogTeachVisible"
      width="80%"
      height="80%"
    >
      <TeachSelector v-on:get-teach="onGetSelectTeach"></TeachSelector>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogTeachVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogTeachVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { alertMessage } from "@/util/alertMessageUtil";
import { marketReciteApi } from "@/api/marketReciteApi.js";
import TeachSelector from "@/component/teachSelector";

export default {
  components: { TeachSelector },
  data() {
    return {
      dialogTeachVisible: false,
      marketRecite: {
        id: "",
        title: "",
        subTitle: "",
        qrCodeId: "",
        teachId: "",
        teachType: "",
        text: "",
        remark: ""
      }
    };
  },
  methods: {
    onGetSelectTeach(val) {
      console.log("on get teach asset", val.teachId, val.teachType);
      this.marketRecite.teachId = val.teachId;
      this.marketRecite.teachType = val.teachType;
      this.dialogTeachVisible = false;
    },
    onAdd() {
      if (
        !this.marketRecite.title ||
        !this.marketRecite.subTitle ||
        !this.marketRecite.teachId ||
        !this.marketRecite.qrCodeId ||
        !this.marketRecite.teachType ||
        this.marketRecite.teachType != 5
      ) {
        alertMessage("输入不合法", "，warning");
        return;
      }
      marketReciteApi
        .save(this.marketRecite)
        .then(ret => {
          if (ret.code == 0) {
            alertMessage("保存成功", "success");
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    invalidWeChat() {
      for (let item of this.marketRecite.weChats) {
        if (!item.wechatId || !item.url) {
          return true;
        }
      }
      return false;
    }
  },
  mounted() {
    if (this.$route.params.id) {
      marketReciteApi.getById(this.$route.params.id).then(ret => {
        if (ret.code === 0) {
          this.marketRecite = ret.data;
        } else {
          alertMessage("获取数据失败，请稍后再试", "warning");
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.group {
  display: flex;
  flex-direction: row;
}
</style>
